var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-column flex-center align-items-center py-10 bgi-size-cover bgi-no-repeat rounded-top",style:({ backgroundImage: `url(${_vm.backgroundHeaderImage})` })},[_c('h4',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"text-white font-weight-bold"},[_vm._v("Dashboard widgets")]),_c('p',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-weight-bold font-size-sm mt-2 text-center text-white w-75"},[_vm._v("Manage your widgets visibility below and drag'n'drop to reorder")])]),_c('b-form-group',{staticClass:"p-8 mt-2 mb-0"},_vm._l((typeof _vm.DashboardSettings === 'object'
        ? Object.entries(_vm.DashboardSettings)
            .sort(([, a], [, b]) => a.Order - b.Order)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
        : {}),function(key,widgetKey){return _c('b-form-checkbox',{key:widgetKey,staticClass:"mb-2",attrs:{"size":"lg","title":widgetKey},on:{"input":function($event){return _vm.changeWidgetVisible({
          widgetKey: widgetKey,
          widgetValue: key.Visible,
        })}},model:{value:(key.Visible),callback:function ($$v) {_vm.$set(key, "Visible", $$v)},expression:"key.Visible"}},[_vm._v(" "+_vm._s(_vm.$gettext(key.Name))+" ")])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }