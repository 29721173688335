<template>
  <div class="topbar align-items-center justify-content-between py-2">
    <div>
      <!--begin::Logotype-->
      <div class="header-mobile-brand ml-4 d-block d-lg-none">
        <router-link to="/">
          <img alt="Opalean" :src="!isMobileDevice ? headerLogo : headerFullLogo" height="30" />
        </router-link>
      </div>
      <!--end::Logotype-->
    </div>

    <small v-if="showDataDebug"
      ><b-badge variant="info">OperationDraft :</b-badge> {{ OperationDraft }} <b-badge variant="info">PartnerDraft :</b-badge> {{ PartnerDraft }}</small
    >

    <div class="d-flex">
      <!-- begin::JSON loader -->
      <div class="json-loader topbar-item" title="Loading..." v-show="showLoader > 0">
        <div class="position-relative btn btn-icon --btn-clean --btn-dropdown btn-lg mr-1">
          <div class="d-flex align-items-center px-6 spinner spinner-secondary"></div>
          <span class="label label-sm label-opalean-info label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-4">{{ showLoader }}</span>
        </div>
      </div>
      <!-- end::JSON Loader -->

      <!--begin: Notifications -->
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="topbar-item text-decoration-none"
        no-caret
        right
        no-flip
        @show="$refs.DropdownNotification.loadNotifications()"
      >
        <template v-slot:button-content>
          <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
            <span class="svg-icon svg-icon-xl">
              <inline-svg src="media/svg/icons/Communication/Mail-box.svg" />
            </span>
            <span
              class="pulse-ring"
              v-if="
                $refs.DropdownNotification !== undefined &&
                $refs.DropdownNotification.NotificationsCount != 0 &&
                $refs.DropdownNotification.NotificationsCount != undefined
              "
            ></span>
            <span
              class="label label-sm label-opalean-info label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1"
              v-if="
                $refs.DropdownNotification !== undefined &&
                $refs.DropdownNotification.NotificationsCount != 0 &&
                $refs.DropdownNotification.NotificationsCount != undefined
              "
              >{{ $refs.DropdownNotification.NotificationsCount }}</span
            >
          </div>
        </template>
        <b-dropdown-text tag="div" class="min-w-md-550px">
          <form>
            <KTDropdownNotification ref="DropdownNotification"></KTDropdownNotification>
            <!-- https://www.w3docs.com/snippets/vue-js/how-to-call-a-vue-js-component-method-from-outside-the-component.html -->
          </form>
        </b-dropdown-text>
      </b-dropdown>
      <!--end: Notifications -->

      <!--begin: Quick Actions -->
      <b-dropdown size="sm" variant="link" toggle-class="topbar-item text-decoration-none" no-caret right no-flip v-show="false">
        <template v-slot:button-content>
          <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
            <span class="svg-icon svg-icon-xl">
              <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
            </span>
          </div>
        </template>
        <b-dropdown-text tag="div" class="min-w-md-350px">
          <KTDropdownQuickAction></KTDropdownQuickAction>
        </b-dropdown-text>
      </b-dropdown>
      <!--end: Quick Actions -->

      <!--begin: Linked tools -->
      <b-dropdown size="sm" variant="link" toggle-class="topbar-item text-decoration-none" no-caret right no-flip v-if="hasLinkedTools()">
        <template v-slot:button-content>
          <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
            <span class="svg-icon svg-icon-xl">
              <inline-svg src="media/svg/icons/Media/Shuffle.svg" />
            </span>
          </div>
        </template>
        <b-dropdown-text tag="div" class="min-w-md-350px">
          <KTDropdownLinkedTools></KTDropdownLinkedTools>
        </b-dropdown-text>
      </b-dropdown>
      <!--end: Linked tools -->

      <!--begin::User-->
      <KTQuickUser></KTQuickUser>
      <!--end::User-->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import statics from "@/core/statics/statics.js";

//import ApiService from "@/core/services/api.service";

import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser";
import KTDropdownLinkedTools from "@/view/layout/extras/dropdown/DropdownLinkedTools.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";

export default {
  name: "Topbar",
  components: {
    KTQuickUser,
    KTDropdownLinkedTools,
    KTDropdownNotification,
    KTDropdownQuickAction,
  },
  data() {
    return {
      // Statics
      linkedTools: statics.linkedTools,
      //Preferences
      showDataDebug: statics.showDataDebug,
    };
  },
  computed: {
    ...mapState({
      showLoader: (state) => state.datas.loading,
      OperationDraft: (state) => state.datas.OperationDraft,
      PartnerDraft: (state) => state.datas.PartnerDraft,
    }),
    ...mapGetters(["layoutConfig", "getDevice", "getUserCapability"]),
    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.light");
    },
    headerFullLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.full");
    },
    isMobileDevice() {
      return this.getDevice === "mobile" ? true : false;
    },
  },
  methods: {
    iconImage(path) {
      return process.env.BASE_URL + path;
    },
    hasLinkedTools() {
      let show = false;
      this.linkedTools.forEach((tool) => {
        tool.capabilities.forEach((capability) => {
          if (this.getUserCapability(capability) != false) show = true;
        });
      });
      return show;
    },
  },
  mounted() {},
};
</script>
