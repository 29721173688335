<template>
  <div>
    <!-- begin::Sticky Toolbar -->
    <ul class="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
      <li v-b-tooltip.hover.left="'Submit'" class="nav-item mb-2">
        <router-link :to="{ name: '' }" class="btn btn-sm btn-icon btn-bg-success text-light btn-text-light btn-hover-opalean-gray-medium">
          <i class="far fa-plus-square text-light"></i>
        </router-link>
      </li>
      <li v-b-tooltip.hover.left="'Cancel'" class="nav-item">
        <a href="#" class="btn btn-sm btn-icon btn-bg-danger text-light btn-text-light btn-hover-opalean-gray-medium" target="_blank">
          <i class="fas fa-undo-alt text-light"></i>
        </a>
      </li>
    </ul>
    <!-- end::Sticky Toolbar -->
  </div>
</template>

<script>
export default {
  name: "KTStickyToolbar",
};
</script>
