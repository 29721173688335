var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topbar align-items-center justify-content-between py-2"},[_c('div',[_c('div',{staticClass:"header-mobile-brand ml-4 d-block d-lg-none"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"alt":"Opalean","src":!_vm.isMobileDevice ? _vm.headerLogo : _vm.headerFullLogo,"height":"30"}})])],1)]),(_vm.showDataDebug)?_c('small',[_c('b-badge',{attrs:{"variant":"info"}},[_vm._v("OperationDraft :")]),_vm._v(" "+_vm._s(_vm.OperationDraft)+" "),_c('b-badge',{attrs:{"variant":"info"}},[_vm._v("PartnerDraft :")]),_vm._v(" "+_vm._s(_vm.PartnerDraft))],1):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoader > 0),expression:"showLoader > 0"}],staticClass:"json-loader topbar-item",attrs:{"title":"Loading..."}},[_c('div',{staticClass:"position-relative btn btn-icon --btn-clean --btn-dropdown btn-lg mr-1"},[_c('div',{staticClass:"d-flex align-items-center px-6 spinner spinner-secondary"}),_c('span',{staticClass:"label label-sm label-opalean-info label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-4"},[_vm._v(_vm._s(_vm.showLoader))])])]),_c('b-dropdown',{attrs:{"size":"sm","variant":"link","toggle-class":"topbar-item text-decoration-none","no-caret":"","right":"","no-flip":""},on:{"show":function($event){return _vm.$refs.DropdownNotification.loadNotifications()}},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"},[_c('span',{staticClass:"svg-icon svg-icon-xl"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Mail-box.svg"}})],1),(
              _vm.$refs.DropdownNotification !== undefined &&
              _vm.$refs.DropdownNotification.NotificationsCount != 0 &&
              _vm.$refs.DropdownNotification.NotificationsCount != undefined
            )?_c('span',{staticClass:"pulse-ring"}):_vm._e(),(
              _vm.$refs.DropdownNotification !== undefined &&
              _vm.$refs.DropdownNotification.NotificationsCount != 0 &&
              _vm.$refs.DropdownNotification.NotificationsCount != undefined
            )?_c('span',{staticClass:"label label-sm label-opalean-info label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1"},[_vm._v(_vm._s(_vm.$refs.DropdownNotification.NotificationsCount))]):_vm._e()])]},proxy:true}])},[_c('b-dropdown-text',{staticClass:"min-w-md-550px",attrs:{"tag":"div"}},[_c('form',[_c('KTDropdownNotification',{ref:"DropdownNotification"})],1)])],1),_c('b-dropdown',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"size":"sm","variant":"link","toggle-class":"topbar-item text-decoration-none","no-caret":"","right":"","no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"btn btn-icon btn-clean btn-dropdown btn-lg mr-1"},[_c('span',{staticClass:"svg-icon svg-icon-xl"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Media/Equalizer.svg"}})],1)])]},proxy:true}])},[_c('b-dropdown-text',{staticClass:"min-w-md-350px",attrs:{"tag":"div"}},[_c('KTDropdownQuickAction')],1)],1),(_vm.hasLinkedTools())?_c('b-dropdown',{attrs:{"size":"sm","variant":"link","toggle-class":"topbar-item text-decoration-none","no-caret":"","right":"","no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"btn btn-icon btn-clean btn-dropdown btn-lg mr-1"},[_c('span',{staticClass:"svg-icon svg-icon-xl"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Media/Shuffle.svg"}})],1)])]},proxy:true}],null,false,2949475733)},[_c('b-dropdown-text',{staticClass:"min-w-md-350px",attrs:{"tag":"div"}},[_c('KTDropdownLinkedTools')],1)],1):_vm._e(),_c('KTQuickUser')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }