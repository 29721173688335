<template>
  <div>
    <!--begin::Header -->
    <div class="d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top" :style="{ backgroundImage: `url(${backgroundHeaderImage})` }">
      <h4 class="text-white font-weight-bold">Smart services</h4>
      <p class="font-weight-bold font-size-sm mt-2" v-translate>Connect swiftly to any third party !</p>
    </div>
    <!--end::Header -->

    <!--begin::Nav -->
    <div class="row row-paddingless">
      <!--begin:Item-->
      <template v-for="(item, i) in linkedTools">
        <div class="col-6" v-bind:key="i" v-if="checkCapabilities(item.capabilities)">
          <a
            :href="item.url"
            class="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom h-100 d-flex flex-column align-items-center justify-content-center"
          >
            <b-media vertical-align="center">
              <b-img :src="iconImage(item.image)" alt="placeholder" fluid width="100"></b-img>
            </b-media>

            <span class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
              {{ item.name }}
            </span>
            <span class="d-block text-dark-50 font-size-lg">
              {{ item.description }}
            </span>
          </a>
        </div>
      </template>
      <!--end:Item-->
    </div>
    <!--end::Nav -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import statics from "@/core/statics/statics.js";

export default {
  name: "KTDropdownLinkedTools",
  data() {
    return {
      linkedTools: statics.linkedTools,
    };
  },
  computed: {
    ...mapGetters(["getUserCapability"]),
    backgroundHeaderImage() {
      return process.env.BASE_URL + "media/opalean/camions.jpg";
    },
  },
  methods: {
    iconImage(path) {
      return process.env.BASE_URL + path;
    },
    checkCapabilities(capabilities) {
      let show = true;
      capabilities.forEach((capability) => {
        if (this.getUserCapability(capability) != true) show = false;
      });
      return show;
    },
  },
};
</script>
