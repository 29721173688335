<template>
  <ul class="menu-nav">
    <!-- begin::sub level menu NeoOpatrace-->
    <!-- end::sub level menu NeoOpatrace-->
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
