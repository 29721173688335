<template>
  <div id="kt_header" ref="kt_header" class="header bg-white header-fixed">
    <!--begin::Container-->
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'py-2': !isMobileDevice, 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Left-->
      <div class="d-flex align-items-stretch mr-2 flex-grow-1" v-if="!isMobileDevice">
        <!--begin::Header Menu Wrapper-->
        <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper" ref="kt_header_menu_wrapper"></div>
        <!--end::Header Menu Wrapper-->

        <!--begin::Quick links-->
        <div class="d-flex align-items-center">
          <!-- Back -->
          <router-link
            to="/operations/list/"
            v-slot="{ href, navigate }"
            custom
            v-if="Object.keys(FilteredOperations).length > 0"
            v-show="$route.path.includes('/operations/edit')"
          >
            <a :href="href" class="btn btn-lg px-3 py-2 px-sm-6 py-sm-4 mr-2 btn-outline-opalean-gray-medium font-weight-bold" @click="navigate">
              <span class="menu-icon"><i class="icon-md ki ki-arrow-back pr-0"></i></span>
            </a>
          </router-link>

          <!-- Create -->
          <router-link :to="`/operations/create/${getOperationTID}`" v-slot="{ href, navigate, isActive, isExactActive }" custom>
            <a
              :href="href"
              class="btn btn-lg px-3 py-2 px-sm-6 py-sm-4 btn-opalean-secondary font-weight-bold"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              @click="navigate"
            >
              <span class="menu-icon"><i class="icon-md ki ki-plus pr-0 pr-sm-3"></i></span>
              <span class="menu-text d-none d-sm-inline-block" v-translate>Create an operation</span>
            </a>
          </router-link>
        </div>
        <!--end::Quick links-->

        <!--begin::Page Title-->
        <h3 class="d-flex --d-none --d-lg-flex text-dark align-items-center ml-5 ml-sm-10 mr-5 mr-sm-10 mb-0 --min-w-250px">
          {{ $gettext($route.name) }}
        </h3>
        <!--end::Page Title-->

        <!--begin::Widget display dropdown -->
        <b-dropdown
          size="sm"
          variant="link"
          class="ml-n5"
          toggle-class="topbar-item text-decoration-none"
          no-caret
          right
          no-flip
          v-show="$route.path.includes('/dashboard')"
        >
          <template v-slot:button-content>
            <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
              </span>
            </div>
          </template>
          <b-dropdown-text tag="div" class="min-w-md-350px">
            <KTDropdownDashboard></KTDropdownDashboard>
          </b-dropdown-text>
        </b-dropdown>
        <!--begin::Widget display dropdown -->

        <!--begin::Header Menu Wrapper-->
        <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper" ref="kt_header_menu_wrapper">
          <!--begin::Header Menu-->
          <div id="kt_header_menu" ref="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default" v-bind:class="headerMenuClasses">
            <!--begin::Header Nav-->
            <KTMenu></KTMenu>
            <!--end::Header Nav-->
          </div>
          <!--end::Header Menu-->
        </div>
        <!--end::Header Menu Wrapper-->
      </div>
      <!--end::Left-->
      <!--begin::Topbar-->
      <KTTopbar></KTTopbar>
      <!--end::Topbar-->

      <!--begin::Aside Mobile Toggle-->
      <button v-if="asideEnabled" class="btn p-0 burger-icon burger-icon-left d-block d-lg-none" id="kt_aside_mobile_toggle" ref="kt_aside_mobile_toggle">
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->

      <!--begin::Logotype-->
      <div class="header-brand --d-flex align-items-center justify-content-end ml-5 d-none d-sm-flex">
        <span class="header-brand-logo">
          <img alt="Opalean" :src="headerFullLogo" width="130" height="26" />
        </span>
      </div>
      <!--end::Logotype-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTMenu from "@/view/layout/header/Menu";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTTopbar from "@/view/layout/header/Topbar";
import KTDropdownDashboard from "@/view/layout/extras/dropdown/DropdownDashboard.vue";
//import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

export default {
  name: "Header",
  components: {
    KTMenu,
    KTTopbar,
    KTDropdownDashboard,
  },
  mounted() {
    console.log("Header.vue");
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(this.$refs["kt_header_menu"], this.$refs["kt_header_menu_wrapper"]);

    // Init Header Topbar For Mobile Mode
    // KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getDevice", "getClasses", "getOperationTID", "getFilteredOperations"]),

    // Get how operations in list was filtered
    FilteredOperations() {
      return this.getFilteredOperations;
    },

    /**
     * Get header logo
     * @returns {string}
     */
    headerFullLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.full");
    },

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return this.getDevice === "mobile" ? this.layoutConfig("aside.mobile.display") : this.layoutConfig("aside.self.display");
    },

    isMobileDevice() {
      return this.getDevice === "mobile" ? true : false;
    },
  },
  methods: {
    getPath(url) {
      return process.env.BASE_URL + url;
    },
  },
};
</script>
