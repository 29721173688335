<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <!-- <KTHeaderMobile></KTHeaderMobile> -->
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <div class="d-flex flex-row flex-column-fluid page">
      <!--begin::Aside-->
      <KTAside v-if="!isMobileDevice"></KTAside>
      <!--end::Aside-->

      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <!--begin::Header-->
        <KTHeader></KTHeader>
        <!--end::Header-->
        <!--begin::Content-->
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <!--begin::Subheader-->
          <KTSubheader v-if="!isMobileDevice"></KTSubheader>
          <!--end::Subheader-->

          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <transition name="fade-in-up" mode="out-in">
                <router-view></router-view>
              </transition>
            </div>
          </div>
        </div>
        <!--end::Content-->

        <!--begin::Footer-->
        <KTFooter v-if="!isMobileDevice"></KTFooter>
        <!--end::Footer-->

        <!--begin::Mobile menu-->
        <div class="h-120px" v-if="isMobileDevice"></div>
        <div class="position-fixed w-100 bottom-0 left-0" v-if="isMobileDevice">
          <KTMenuMobile v-if="isMobileDevice"></KTMenuMobile>
        </div>
        <!--end::Mobile menu-->

        <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
        <KTScrollTop></KTScrollTop>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import HtmlClass from "@/core/services/htmlclass.service";
import KTAside from "@/view/layout/aside/Aside.vue";
// import KTHeaderMobile from "@/view/layout/header/HeaderMobile";
import Loader from "@/view/layout/content/Loader";
import KTHeader from "@/view/layout/header/Header";
import KTSubheader from "@/view/layout/subheader/Subheader";
import KTMenuMobile from "@/view/layout/header/MenuMobile";
import KTFooter from "@/view/layout/footer/Footer";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    //KTHeaderMobile,
    Loader,
    KTHeader,
    KTFooter,
    KTSubheader,
    KTMenuMobile,
    KTStickyToolbar,
    KTScrollTop,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  methods: {
    ...mapMutations(["setUser", "setPartners"]),
    // Global check auth
    checkAuthentification(auth) {
      console.log("[AUTH] checkAuthentification::", auth);
      if (!auth) {
        console.info("Layout.vue::User is not authenticated (isAuthenticated) -----------------------", this.$router.currentRoute.name);
        console.info("@Wilhem Layout.vue:: DO WE NEED ? route to login here ? ");
        //if (this.$router.currentRoute.name !== "login") this.$router.push({ name: "login" });
      } else {
        console.info("Layout.vue::User is authenticated (isAuthenticated) -----------------------", this.$router.currentRoute.name);
        // Then delay page loading removing
        setTimeout(() => {
          // Remove page loader after some time
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }, 100);
      }
    },
  },
  watch: {
    isAuthenticated: {
      immediate: false, // Initiate at first load, trigger the callback immediately with the current value of the expression
      handler: function (n) {
        console.log("Layout.vue::Watch::isAuthenticated");
        this.checkAuthentification(n);
      },
    },
  },
  mounted() {
    console.log("Layout.vue::Mount::");
    // Check if current user is authenticated
    this.checkAuthentification(this.isAuthenticated);
  },
  computed: {
    ...mapGetters(["isAuthenticated", "breadcrumbs", "pageTitle", "layoutConfig", "getDevice"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
      // return this.isMobileDevice;
    },

    isMobileDevice() {
      return this.getDevice === "mobile" ? true : false;
    },
  },
};
</script>
