<template>
  <div>
    <!--begin::Header -->
    <div
      class="d-flex flex-column flex-center align-items-center py-10 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundHeaderImage})` }"
    >
      <h4 class="text-white font-weight-bold" v-translate>Dashboard widgets</h4>
      <p class="font-weight-bold font-size-sm mt-2 text-center text-white w-75" v-translate>Manage your widgets visibility below and drag'n'drop to reorder</p>
    </div>
    <!--end::Header -->

    <!--begin::Checkboxs -->
    <b-form-group class="p-8 mt-2 mb-0">
      <b-form-checkbox
        v-for="(key, widgetKey) in typeof DashboardSettings === 'object'
          ? Object.entries(DashboardSettings)
              .sort(([, a], [, b]) => a.Order - b.Order)
              .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
          : {}"
        :key="widgetKey"
        v-model="key.Visible"
        size="lg"
        class="mb-2"
        :title="widgetKey"
        @input="
          changeWidgetVisible({
            widgetKey: widgetKey,
            widgetValue: key.Visible,
          })
        "
      >
        {{ $gettext(key.Name) }}
      </b-form-checkbox>
    </b-form-group>
    <!-- Removed @input="
          changeWidgetVisible({
            widgetKey: key.Name,
            widgetValue: key.Visible >> ICI mettre plutot la value de la CB ? 
          })
        " -->
    <!--end::Checkboxs -->
  </div>
</template>

<script>
console.log("DropdownDashboard.vue");
import { mapState, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";

//import Swal from "sweetalert2";
// import { swalToast } from "@/main.js"; // Import swal presets

export default {
  name: "DropdownDashboard",
  data() {
    return {
      //DashboardSettings: []
      //Watcher
      lastDashboardSettings: [],
    };
  },
  computed: {
    //A
    // ...mapGetters(["getDashboardSettings"]),
    // DashboardSettings: function() {
    //   return this.getDashboardSettings;
    // },
    //B
    //https://woetflow.com/posts/using-v-model-to-bind-user-input-to-state-in-the-vuex-store/
    ...mapState({ Settings: (state) => state.datas.Settings }),
    DashboardSettings: function () {
      // return {
      //   ActivityWidget: {
      //     Name: "Activity",
      //     Order: 0,
      //     Visible: true,
      //   },
      //   BalanceWidget: {
      //     Name: "Balance",
      //     Expanded: false,
      //     Order: 1,
      //     Visible: true,
      //   },
      //   AlertsWidget: {
      //     Name: "Alerts",
      //     Order: 2,
      //     Visible: true,
      //   },
      //   CreditsWidget: {
      //     Name: "Credits",
      //     Order: 3,
      //     Visible: true,
      //   },
      //   DebtsWidget: {
      //     Name: "Debts",
      //     Order: 4,
      //     Visible: true,
      //   },
      //   TotalsWidget: {
      //     Name: "Totals",
      //     Order: 5,
      //     Visible: false,
      //   },
      //   LatestOperationsWidget: {
      //     Name: "Latest operations",
      //     Order: 6,
      //     Visible: true,
      //   },
      //   NewsWidget: {
      //     Name: "News",
      //     Order: 7,
      //     Visible: true,
      //   },
      //   ContactWidget: {
      //     Name: "Contact",
      //     Order: 8,
      //     Visible: true,
      //   },
      // }; // Bug

      return this.Settings.DashboardSettings;
      //return _.orderBy(this.Settings.DashboardSettings, "Order") ?? []; >> Do not reorder object here because it breaks the drag and drop
    },
    backgroundHeaderImage() {
      return process.env.BASE_URL + "media/opalean/barre.jpg";
    },
  },
  methods: {
    // ...mapMutations(["setDashboardSettings"]),
    ...mapActions(["changeWidgetVisible"]),
    // DashboardSettings: function() {
    //   return this.Settings;
    // }

    // Watcher debounce
    // Used to make a deep diff of partner object
    diffDashboardSettings: _.debounce(function (n, o) {
      console.log("debounced diff Watch::DashboardSettings", "n", n, "o", o, this.$mainFunctions.diff({ DashboardSettings: n }, { DashboardSettings: o }));
      // Store a non-reactive compared object
      let keepKeys = []; // "Attributes"
      let diffDashboardSettings = Object.assign({}, this.$mainFunctions.diff({ DashboardSettings: n }, { DashboardSettings: o }, false, false, keepKeys)); // Dernier argument = keepIndexes
      console.log("DashboardSettings:: diff =", diffDashboardSettings, typeof diffDashboardSettings.DashboardSettings);

      if (typeof diffDashboardSettings.DashboardSettings == "object") {
        // Uncaught TypeError: diffDashboardSettings.DashboardSettings is undefined On switch client User
        console.log("DashboardSettings::", "Yes, we update the settings !");
        this.postDashboardSettings(n);
      }
    }, 300), // Reducing delay from 1000 to 300 to prevent quick send errors

    // Post settings
    postDashboardSettings(n) {
      console.log("DashboardSettings::POST API:: /user");

      // Array way
      // let _DashboardSettings = _.orderBy(n, "Order");
      // console.log("DashboardSettings::POST API:: /user >> REORDERED ARRAY WAY", _DashboardSettings);

      // Object way
      // https://stackoverflow.com/questions/1069666/sorting-object-property-by-values
      // let _DashboardSettings = Object.entries(n)
      //   .sort(([, a], [, b]) => a.Order - b.Order)
      //   .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      // console.log("DashboardSettings::POST API:: /user >> REORDERED OBJECT WAY", Object.keys(n), Object.keys(_DashboardSettings));

      // Post User
      (async () => {
        let response = null;
        let errorStatus = null;
        let errorData = null;
        try {
          response = await ApiService.post("/user", { Settings: { DashboardSettings: n } });
        } catch (error) {
          console.error("Error response:");
          console.error(error.response.data); // ***
          console.error(error.response.status); // ***
          console.error(error.response.headers); // ***
          errorStatus = error.response.status;
          errorData = error.response.data.errors.join(", ");
        } finally {
          console.log(typeof response);
          console.log(typeof errorStatus);
          console.log(typeof errorData);

          // now see main.js
          // const Toast = Swal.mixin({
          //   position: "top-end",
          //   toast: true,
          //   showConfirmButton: false,
          //   timer: 2000,
          //   timerProgressBar: true,
          //   didOpen: (toast) => {
          //     toast.addEventListener("mouseenter", Swal.stopTimer);
          //     toast.addEventListener("mouseleave", Swal.resumeTimer);
          //   },
          //   showClass: {
          //     popup: "animate__animated animate__faster animate__fadeInDown",
          //   },
          //   hideClass: {
          //     popup: "animate__animated animate__faster animate__fadeOutUp",
          //   },
          //   // confirmButtonClass: "btn btn-secondary",
          //   customClass: {
          //     popup: "px-5",
          //     content: "mt-0",
          //   },
          // });

          if (response === null && errorStatus !== null) {
            // Error
            window.swalAlert.fire({
              title: errorStatus,
              html: "Damned, we are sorry, we have an error..." + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
              icon: "error",
              //footer: '<a href="mailto:support@opalean.fr">' + vm.$gettext('Need to contact support ?') + '</a>',
            });
          } else if (response !== null && response.status === 200) {
            // Success
            window.swalToast.fire({
              title: "User settings",
              html: "The user settings has been successfully <em>stored</em> !",
              icon: "success",
              //footer: '<a href="mailto:support@opalean.fr">' + vm.$gettext('Need to contact support ?') + '</a>',
            });
          }
        }
      })();
    },
  },
  mounted() {
    // this.DashboardSettings = this.getDashboardSettings;
  },
  watch: {
    DashboardSettings: {
      immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
      deep: true, // Look deeper
      handler: function (n, o) {
        console.log("Watch::DashboardSettings", n, o, "last::", this.lastDashboardSettings, n !== undefined && o !== undefined); // @WIlhem TODO : attention n = o

        // Not the first load
        if (n !== undefined && o !== undefined) {
          // Compare two arrays
          // No old object when it's an object
          // Note: `newValue` will be equal to `oldValue` here
          // on nested mutations as long as the object itself
          // hasn't been replaced.
          this.diffDashboardSettings(n, this.lastDashboardSettings);
          //console.log("DashboardSettings::diff Watch::", diffDashboardSettings);

          // || (o.length != 0 && n.length == 0)
          // Then store after a timeout
          // setTimeout(() => {
          //   console.log("DashboardSettings::POST API:: /user");

          //   let _DashboardSettings = _.orderBy(n, "Order");
          //   console.log("DashboardSettings::POST API:: /user >> REORDERED", _DashboardSettings);

          //   // Post User
          //   (async () => {
          //     let response = null;
          //     let errorStatus = null;
          //     let errorData = null;
          //     try {
          //       response = await ApiService.post("/user", { Settings: { DashboardSettings: _DashboardSettings } });
          //     } catch (error) {
          //       console.error("Error response:");
          //       console.error(error.response.data); // ***
          //       console.error(error.response.status); // ***
          //       console.error(error.response.headers); // ***
          //       errorStatus = error.response.status;
          //       errorData = error.response.data.errors.join(", ");
          //     } finally {
          //       console.log(typeof response);
          //       console.log(typeof errorStatus);
          //       console.log(typeof errorData);
          //       if (response === null && errorStatus !== null) {
          //         // Error
          //         Swal.fire({
          //           title: errorStatus,
          //           html: "Damned, we are sorry, we have an error..." + (errorData !== null ? "<br/><code> " + errorData + " </code>" : ""),
          //           icon: "error",
          //           confirmButtonClass: "btn btn-secondary",
          //           footer: '<a href="mailto:support@opalean.fr">' + vm.$gettext('Need to contact support ?') + '</a>',
          //         });
          //       } else if (response !== null && response.status === 200) {
          //         // Success
          //         Swal.fire({
          //           title: "User settings",
          //           html: "The user settings has been successfully <em>stored</em> !",
          //           icon: "success",
          //           timer: 1200,
          //           confirmButtonClass: "btn btn-secondary",
          //         });
          //       }
          //     }
          //   })();
          // }, 500);
        }

        // Store last DashboardSettings
        this.lastDashboardSettings = this.$mainFunctions.noReactiveCopy(n); // Make non-reactive
      },
    },
    //   DashboardSettings(newSettings) {
    //     console.log("Watch::DashboardSettings");
    //     console.log(newSettings[0].Name);
    //     this.DashboardSettings = newSettings;
    //   }
    // changeWidgetVisible: {
    //   immediate: true, // Initiate at first load, trigger the callback immediately with the current value of the expression
    //   deep: true,
    //   handler: function (n, o) {
    //     console.log("Watch::changeWidgetVisible");
    //   },
    // },
  },
};
</script>
