<template>
  <div class="aside aside-left d-flex flex-column" id="kt_aside" ref="kt_aside">
    <!--begin::Brand-->
    <KTBrand></KTBrand>
    <!--end::Brand-->
    <!--begin::Nav Wrapper-->
    <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-7">
      <!--begin::Nav-->
      <ul class="nav flex-column">
        <!--begin::Item-->
        <!-- v-b-tooltip.hover.bottomright.ds400.window="$gettext('A quick view to all activities')" -->
        <router-link to="/dashboard" custom v-slot="{ href, navigate, isActive, isExactActive }" class="position-relative">
          <li
            class="nav-item mb-3 d-flex flex-column justify-content-center align-items-center"
            :class="[isActive && 'nav-item-active', isExactActive && 'nav-item-exact-active']"
          >
            <a
              :href="href"
              :active="isActive"
              class="nav-link btn btn-icon btn-icon-white btn-lg stretched-link"
              :class="[isActive && 'btn-clean active', isExactActive && 'btn-clean exact-active']"
              @click="navigate"
            >
              <i class="flaticon2-shelter icon-lg"></i>
            </a>
            <span class="menu-text text-white mt-1 font-size-md font-weight-bold text-center">{{ $gettext("menu.dashboard") }}</span>
          </li>
        </router-link>
        <!--end::Item-->

        <!--begin::Item-->
        <router-link to="/operations" custom v-slot="{ href, navigate, isActive, isExactActive }" class="position-relative">
          <li
            class="nav-item mb-3 d-flex flex-column justify-content-center align-items-center"
            :class="[isActive && 'nav-item-active', isExactActive && 'nav-item-exact-active']"
          >
            <a
              :href="href"
              :active="isActive"
              class="nav-link btn btn-icon btn-icon-white btn-lg stretched-link"
              :class="[isActive && 'btn-clean active', isExactActive && 'btn-clean exact-active']"
              @click="navigate"
            >
              <i class="flaticon2-lorry icon-lg"></i>
            </a>
            <span class="menu-text text-white mt-1 font-size-md font-weight-bold text-center">{{ $gettext("menu.operations") }}</span>
          </li>
        </router-link>
        <!--end::Item-->

        <!--begin::Item-->
        <router-link to="/accounts" custom v-slot="{ href, navigate, isActive, isExactActive }" class="position-relative">
          <li
            class="nav-item mb-3 d-flex flex-column justify-content-center align-items-center"
            :class="[isActive && 'nav-item-active', isExactActive && 'nav-item-exact-active']"
          >
            <a
              :href="href"
              :active="isActive"
              class="nav-link btn btn-icon btn-icon-white btn-lg stretched-link"
              :class="[isActive && 'btn-clean active', isExactActive && 'btn-clean exact-active']"
              @click="navigate"
            >
              <i class="flaticon2-layers-2 icon-lg"></i>
            </a>
            <span class="menu-text text-white mt-1 font-size-md font-weight-bold text-center">{{ $gettext("menu.accounts") }}</span>
          </li>
        </router-link>
        <!--end::Item-->

        <!--begin::Item-->
        <router-link
          to="/stocks"
          custom
          v-slot="{ href, navigate, isActive, isExactActive }"
          v-if="getUserCapability('CanInventory')"
          class="position-relative"
        >
          <li
            class="nav-item mb-3 d-flex flex-column justify-content-center align-items-center"
            :class="[isActive && 'nav-item-active', isExactActive && 'nav-item-exact-active']"
          >
            <a
              :href="href"
              :active="isActive"
              class="nav-link btn btn-icon btn-icon-white btn-lg stretched-link"
              :class="[isActive && 'btn-clean active', isExactActive && 'btn-clean exact-active']"
              @click="navigate"
            >
              <i class="flaticon2-cube icon-lg"></i>
            </a>
            <span class="menu-text text-white mt-1 font-size-md font-weight-bold text-center">{{ $gettext("menu.stocks") }}</span>
          </li>
        </router-link>
        <!--end::Item-->

        <!--begin::Item-->
        <router-link to="/map" custom v-slot="{ href, navigate, isActive, isExactActive }" v-if="getUserCapability('CanGeoloc')" class="position-relative">
          <li
            class="nav-item mb-3 d-flex flex-column justify-content-center align-items-center"
            :class="[isActive && 'nav-item-active', isExactActive && 'nav-item-exact-active']"
          >
            <a
              :href="href"
              :active="isActive"
              class="nav-link btn btn-icon btn-icon-white btn-lg stretched-link"
              :class="[isActive && 'btn-clean active', isExactActive && 'btn-clean exact-active']"
              @click="navigate"
            >
              <i class="flaticon2-position icon-lg"></i>
            </a>
            <span class="menu-text text-white mt-1 font-size-md font-weight-bold text-center">{{ $gettext("menu.map") }}</span>
          </li>
        </router-link>
        <!--end::Item-->

        <!--begin::Item-->
        <router-link to="/partners" custom v-slot="{ href, navigate, isActive, isExactActive }" class="position-relative">
          <li
            class="nav-item mb-3 d-flex flex-column justify-content-center align-items-center"
            :class="[isActive && 'nav-item-active', isExactActive && 'nav-item-exact-active']"
          >
            <a
              :href="href"
              :active="isActive"
              class="nav-link btn btn-icon btn-icon-white btn-lg stretched-link"
              :class="[isActive && 'btn-clean active', isExactActive && 'btn-clean exact-active']"
              @click="navigate"
            >
              <i class="flaticon2-calendar-3 icon-lg"></i>
            </a>
            <span class="menu-text text-white mt-1 font-size-md font-weight-bold text-center">{{ $gettext("menu.partners") }}</span>
          </li>
        </router-link>
        <!--end::Item-->

        <!--begin::Item-->
        <router-link to="/reports" custom v-slot="{ href, navigate, isActive, isExactActive }" class="position-relative">
          <li
            class="nav-item mb-3 d-flex flex-column justify-content-center align-items-center"
            :class="[isActive && 'nav-item-active', isExactActive && 'nav-item-exact-active']"
          >
            <a
              :href="href"
              :active="isActive"
              class="nav-link btn btn-icon btn-icon-white btn-lg stretched-link"
              :class="[isActive && 'btn-clean active', isExactActive && 'btn-clean exact-active']"
              @click="navigate"
            >
              <i class="flaticon2-graph-2 icon-lg"></i>
            </a>
            <span class="menu-text text-white mt-1 font-size-md font-weight-bold text-center">{{ $gettext("menu.reports") }}</span>
          </li>
        </router-link>
        <!--end::Item-->

        <!--begin::Item-->
        <!--   SUPPORT 
        <router-link
          to="/support"
          custom
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            class="
              nav-item
              mb-3
              d-flex
              flex-column
              justify-content-center
              align-items-center
            "
            :class="[
              isActive && 'nav-item-active',
              isExactActive && 'nav-item-exact-active'
            ]"
            v-b-tooltip.hover.bottomright.ds400.window="$gettext('Get help whenever is needed')"
          >
            <a
              :href="href"
              :active="isActive"
              class="nav-link btn btn-icon btn-icon-white btn-lg"
              :class="[
                isActive && 'btn-clean active',
                isExactActive && 'btn-clean exact-active'
              ]"
              @click="navigate"
            >
              <i class="flaticon2-help icon-lg"></i>
            </a>
            <span
              class="
                menu-text
                text-white
                mt-1
                font-size-md font-weight-bold
                text-center
              "
              >{{ $gettext("menu.support") }}</span
            >
          </li>
        </router-link>
        -->
        <!--end::Item-->
      </ul>
      <!--end::Nav-->
    </div>
    <!--end::Nav Wrapper-->
    <!--begin::Footer-->
    <!-- <div
      class="
        aside-footer
        d-flex
        flex-column
        align-items-center
        flex-column-auto
        py-8
      "
    >
      removed::Quick Panel
    </div> -->
    <!--end::Footer-->
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import KTBrand from "@/view/layout/brand/Brand";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";

import { mapGetters } from "vuex";

export default {
  name: "Aside",
  components: {
    KTBrand,
  },
  computed: {
    ...mapGetters(["getUserCapability"]),
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);
    });
  },
};
</script>
