<template>
  <div>
    <!-- DEV -->
    <b-card class="card-custom gutter-b mt-10" body-class="card-scroll h-250px p-3" v-if="showDataDebug">
      {{ $route.path }}
      <pre>Operations length: {{ Operations.length }}</pre>
      <pre>OperationsCountStatusM: {{ OperationsCountStatusM.length }}</pre>
    </b-card>

    <ul class="stacked-menu d-flex flex-row align-items-center justify-content-between m-0">
      <!-- begin::mobile menu NeoOpatrace-->

      <!--  v-show="$route.path.includes('/dashboard') || $route.path.includes('/accounts')" -->
      <!-- TEST -->
      <router-link to="/operations/create" v-show="!$route.path.includes('/operations/edit')" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item bg-light-primary col-3 h-120-px"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-exact-active']"
        >
          <a :href="href" class="menu-link text-primary" @click="navigate">
            <span class="menu-icon"><i class="ki ki-plus icon-md text-primary"></i></span>
            <span class="menu-text"><translate>Create an operation</translate></span>
          </a>
        </li>
      </router-link>

      <!-- To validate operations -->
      <router-link
        custom
        :to="{ name: 'route.mobile.operations.list', params: { limit: 9999, Status: 'M', runCallBySearch: true } }"
        v-show="!$route.path.includes('/operations/edit')"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item --bg-info col-3 h-120-px"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-exact-active', `bg-${getStatusType('M', 'class')}`]"
        >
          <a :href="href" class="menu-link text-light" @click="navigate">
            <!-- Icon -->
            <span class="svg-icon svg-icon-2x ml-2 svg-icon-white">
              <inline-svg v-bind:src="`${getStatusType('M', 'icon')}`" />
            </span>
            <!-- <i class="flaticon2-lorry icon-lg text-light"></i> -->
            <!-- Text -->
            <span class="menu-text px-1"><translate>Validate operation</translate></span>
            <span class="font-size-h4 font-weight-normal">{{ OperationsCountStatusM.length }}</span>
          </a>
        </li>
      </router-link>

      <!-- Last operations -->
      <router-link
        custom
        :to="{ name: 'route.mobile.operations.list', params: { limit: 50, runCallBySearch: true } }"
        v-show="!$route.path.includes('/operations/edit')"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item bg-primary col-3 h-120-px"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-exact-active']"
        >
          <a :href="href" class="menu-link text-light" @click="navigate">
            <!-- Icon -->
            <!-- <span class="svg-icon svg-icon-2x ml-2 svg-icon-white">
              <inline-svg v-bind:src="`${getStatusType('M', 'icon')}`" />
            </span> -->
            <i class="flaticon2-lorry icon-lg text-light"></i>
            <!-- Text -->
            <span class="menu-text px-1"><translate>Latest</translate><br /><translate>operations</translate></span>
          </a>
        </li>
      </router-link>

      <router-link to="/mobile/scan" v-show="!$route.path.includes('/operations/edit')" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item bg-opalean-gray-medium col-3 h-120-px"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-exact-active']"
        >
          <a :href="href" class="menu-link text-light" @click="navigate">
            <span class="menu-icon"><i class="la la-barcode icon-xxl text-light"></i></span>
            <span class="menu-text px-1"><translate>Scan an operation Qrcode</translate></span>
          </a>
        </li>
      </router-link>

      <router-link to="/" v-show="$route.path.includes('/operations/edit')" v-slot="{ href, navigate, isActive, isExactActive }">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item bg-opalean-gray-medium col-12 h-60px"
          :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-exact-active']"
        >
          <a :href="href" class="menu-link text-light d-flex flex-row align-items-center justify-content-center" @click="navigate">
            <span class="menu-icon"><i class="la la-long-arrow-alt-left icon-xxl text-light"></i></span>
            <span class="menu-text px-1"><translate>Back</translate></span>
          </a>
        </li>
      </router-link>

      <!-- end::mobile menu NeoOpatrace-->
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.stacked-menu {
  .menu-item {
    text-decoration: none;
    background: silver;
    height: 120px;
    border-right: solid 1px rgba(255, 255, 255, 0.15);

    list-style: none;

    &:last-child {
      border: 0;
    }

    &.menu-item-exact-active {
      border-bottom: rgba(255, 255, 255, 0.25) 6px solid;
    }

    a.menu-link {
      text-align: center;
      font-weight: bold;

      height: 100%;
      display: flex;
      flex-direction: column;

      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      i.menu-icon {
        display: block;
      }
    }
  }
}
</style>

<script>
import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

import ApiService from "@/core/services/api.service";

export default {
  name: "KTMenuMobile",
  data() {
    return {
      // Preferences
      showDataDebug: statics.showDataDebug,
      //
      Operations: [],
      OperationsCountStatusM: [],
    };
  },
  created() {
    this.runCall(9999, undefined, undefined, undefined, "M"); // Dernier status
  },
  mixins: [helpers],
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    runCall(limit, partner, fromdate, todate, status) {
      console.log("runCall:: ( from mobile menu ) ", limit, partner, fromdate, todate, status);
      let params = "";
      params += limit !== undefined && limit !== "" ? "limit=" + limit : "";
      params += partner !== undefined && partner !== "" ? "&partner=" + partner.PartnerID : "";
      params += fromdate !== undefined && fromdate !== "" ? "&fromdate=" + fromdate : "";
      params += todate !== undefined && todate !== "" ? "&todate=" + todate : "";
      params += status !== undefined && status !== "" ? "&status=" + status : "";
      // Call API
      ApiService.get("/operations", params).then((response) => {
        // console.log("/operations::response", response);
        if (response.data.Operations.length) {
          this.Operations = response.data.Operations;
          this.OperationsCountStatusM = response.data.Operations.filter((o) => o.OperationStatus === "M");
        }
      });
    },
  },
  watch: {
    // Watch
    $route(to, from) {
      console.log("[MENU MOBILE] watch router::to,from", to, from);
      // Refetch operation to get count list
      this.runCall(9999, undefined, undefined, undefined, "M");
    },
  },
};
</script>
